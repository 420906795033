import * as React from "react";

export const Emisije: React.FC = () => {
  return (
    <>
      <section className="page-header-emisije">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3>Emisije</h3>

              <p className="page-breadcrumb">
                <a href="/">Početna</a> / Emisije
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/pzAjmq8ArtY?si=I2GVjJEInnZOIPV-"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Katun namijenjen ljubiteljima planine - Dolovi (Epizoda
                      11)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 08. 2024.
                  </p>

                  <p>
                    Ekipa Đedovine u Dolovima na Bjelasici. Gazdinstvo Janketić
                    okuplja prave ljubitelje prirode koji mogu uživati u pogledu
                    na vrhove Bjelasice i obići neka od obližnjih jezera.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/c0uFPdwhfMA?si=NYKUBk6yZCe0gjdZ"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Krš koji čuva prošlost i daje budućnost - Selo Dide
                      (Epizoda 10)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 07. 2024.
                  </p>

                  <p>
                    Ekipa emisije Đedovina u selu Dide nadomak Cetinja.
                    Pogledajte kako crnogorski krš može da oplemeni dobra ideja
                    i želja da se od tradicije napravi budućnost.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/N7TqYxWWGMY?si=1tlBR23KiZK7QZe3"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Priča domaćina i nasleđe Mojkovačke bitke (Epizoda 9)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 06. 2024.
                  </p>

                  <p>
                    Ekipa Đedovine na Bojnoj njivi nadomak Mojkovca. Osim po
                    istorijskim događajima ovaj kraj danas piše novu istoriju.
                    Seoski turizam sve je primamljiviji za ljubitelje netaknute
                    prirode.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/N7TqYxWWGMY?si=1tlBR23KiZK7QZe3"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Priča domaćina i nasleđe Mojkovačke bitke (Epizoda 9)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 06. 2024.
                  </p>

                  <p>
                    Ekipa Đedovine na Bojnoj njivi nadomak Mojkovca. Osim po
                    istorijskim događajima ovaj kraj danas piše novu istoriju.
                    Seoski turizam sve je primamljiviji za ljubitelje netaknute
                    prirode.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/NOMCJ7riuWE?si=sS1krcokwvVDHuC-"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">Kora hljeba najslađa u đedovini (Epizoda 8)</a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 05. 2024.
                  </p>

                  <p>
                    Ekipa Đedovine u posjeti Martinu Cacoviću živi svoj san.
                    Tradiciju i kulturu svojih predaka čuva u "Staroj pjesmi"
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/_IwAagPg1KY?si=eS93q8PifxY5tM1m"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Pastirska priča nadomak Danilovgrada (Epizoda 7)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 04. 2024.
                  </p>

                  <p>
                    U ovoj epizodi pogledajte priču mladog pastira iz mjesta
                    Grlić nadomak Danilovgrada. Svoj život posvetio je rodnom
                    kraju, a sa svega 33 godine danas je ugledan domaćin.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/I3ChaQCFnn8?si=FS9WxzQsW1tN5e78"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Tradicija i ljubav pretočeni u vino (Epizoda 6)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 03. 2024.
                  </p>

                  <p>
                    Emisija Đedovine u novoj epizodi bavi se domaćim
                    proizvođačima vina, u susret Montevinu - najvećoj vinskoj
                    manifestaciji u zemlji.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/-YwMDHvMqrM?si=1XDi4dqJGp6gs8NN"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Bajice - Spoj ruralnog i urbanog života (Epizoda 5)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 02. 2024.
                  </p>

                  <p>
                    Ekipa Đedovine u posjeti Bajicama. Kraj nadomak Cetinja
                    predstavlja spoj urbanog i ruralnog života.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/xUFBzuQ2AzI?si=uy8dfjS5os1AXA54"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Godinje - zaboravljeni dragulj Crmnice (Epizoda 4)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 12. 2023.
                  </p>

                  <p>
                    Ekipa Đedovine u posjeti Godinju. Zaseok Lekovića godinama
                    okuplja turiste iz svih krajeva svijeta zbog čega mladi
                    danas šansu za biznis vide upravo tu.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/wEt9QaUeKso?si=12QqmlhDI7tYxEv0"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Pepiće - od prirode darovano od ljudi zaboravljeno
                      (Epizoda 3)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 11. 2023.
                  </p>

                  <p>
                    Pepiće se smatra najljepšim selom u limskoj dolini. Iako je
                    od prirode darovano, od ljudi je zaboravljeno.
                  </p>
                </div>
              </article>

              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/dZfBRDzsn1g?si=VC7JebvXDSB8c0JM"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">Hajla - kraljica planina (Epizoda 2)</a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp; Premijera: 15. 10. 2023.
                  </p>

                  <p>
                    Hajla je jedna od najviših planina, a često je zovu i
                    kraljicom. Idealna je za izdig stoke na katune, a njeni
                    vrhovi okupljaju na stotine planinara i ljubitelja prirode.
                  </p>
                </div>
              </article>
              <article className="post single-post">
                <div className="single-post-content video-container">
                  <iframe
                    src="https://www.youtube.com/embed/smZX2k2mr94?si=K_GP-tPxLuqXknLF"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="single-post-title">
                  <h2>
                    <a href="#">
                      Pošćenska jezera dragulji Durmitora (Epizoda 1)
                    </a>
                  </h2>

                  <p className="single-post-meta">
                    &nbsp;<i className="fa fa-calendar"></i>
                    &nbsp;Premijera: 15. 9. 2023.
                  </p>

                  <p>
                    Prva epizoda emisije Đedovina snimljena je u mjestu Pošćenje
                    nadomak Šavnika. Pošćenje sa dva jezera, kanjonom i
                    vodopadom nosi titulu najljepšeg sela na Balkanu.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
